.addlist h3 {
  padding: 10px 0;
}
.zsBtn {
  margin-left: 20px;
}
.zsBtn button {
  height: 40px;
}
.certificate-box > div:last-child {
  border: 0;
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
.exportdialog .upload-workers {
  height: 30px;
}
.exportdialog .upload-workers .el-upload {
  height: 30px !important;
  width: 97px;
  border: none !important;
}
.exportdialog .upload-workers .el-upload .el-button {
  padding: 5px 0;
}
.exportdialog .el-dialog__title {
  font-size: 16px;
}
.exportdialog .export-box {
  width: 100%;
  margin-bottom: 20px;
}
.exportdialog .export-box h1 {
  padding: 0 5px 10px;
  display: flex;
  align-items: flex-end;
  font-size: 40px;
  border-bottom: 1px dashed #ccc;
}
.exportdialog .export-box h1 span {
  margin-left: 20px;
  font-size: 14px;
}
.exportdialog .export-box > div {
  padding-left: 30px;
}
.exportdialog .export-box .el-button.is-disabled,
.exportdialog .export-box .el-button.is-disabled:focus,
.exportdialog .export-box .el-button.is-disabled:hover {
  color: #fff;
  background: #c3c3c3;
  border: none;
}
.docsDialog .el-form-item {
  margin-bottom: 0.5rem;
}
.studentNum {
  margin: 20px 0 10px;
}
.studentNum span {
  margin: 0 10px;
}





















































































































































































































































































































































































































































.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.upload-workers {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
